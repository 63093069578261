import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(store);
Vue.directive('focus', {
  "inserted"(el) {
    // 可以对 el 标签，扩展额外功能
    el.focus()
  }
})
new Vue({
  router,
  render: h => h(App),
  store
}).$mount('#app')
