import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        user: localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):null,
        messages: [],
        selectedGroup: 0
    },
    mutations: {
        deleteUser(state) {
            state.user = {};
        },
        updateUser(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        ADD_MESSAGE(state, message) {
            state.messages.push(message);
            localStorage.setItem('messages', JSON.stringify(state.messages));
        },
        LOAD_MESSAGES(state) {
            // 从本地存储加载消息
            const messages = localStorage.getItem('messages');
            if (messages) {
                state.messages = JSON.parse(messages);
            }
        },
        Save_Messages(state) {
            localStorage.setItem('messages', JSON.stringify(state.messages));
        },
        UpDateSelectedGroup(state, group) {
            state.selectedGroup = group;
        }
    },
    actions: {
        addMessage({ commit }, message) {
            commit('ADD_MESSAGE', message);
        },
        loadMessages({ commit }) {
            commit('LOAD_MESSAGES');
        },
        doUpdateUser({ commit }, user) {
            commit('updateUser', user);
        }
    },
    getters: {
        allMessages: state => state.messages
    }
});
store.dispatch('loadMessages');

export default store;